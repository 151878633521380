module.exports = [{
      plugin: require('/usr/src/app/node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.myhr.works"},
    },{
      plugin: require('/usr/src/app/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-NQRXN9B","includeInDevelopment":false,"gtmAuth":"C1TAYhpL6bcPejYHfPoxuw","gtmPreview":"env-2"},
    },{
      plugin: require('/usr/src/app/node_modules/gatsby-plugin-rollbar/gatsby-browser.js'),
      options: {"plugins":[],"accessToken":"91645d5f47ef421aa418b79ec1d094af","captureUncaught":true,"captureUnhandledRejections":true,"payload":{"environment":"production"}},
    },{
      plugin: require('/usr/src/app/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
