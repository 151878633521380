// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-page-jsx": () => import("/usr/src/app/src/templates/page.jsx" /* webpackChunkName: "component---src-templates-page-jsx" */),
  "component---src-templates-white-paper-jsx": () => import("/usr/src/app/src/templates/white-paper.jsx" /* webpackChunkName: "component---src-templates-white-paper-jsx" */),
  "component---src-templates-live-jsx": () => import("/usr/src/app/src/templates/live.jsx" /* webpackChunkName: "component---src-templates-live-jsx" */),
  "component---src-templates-customer-story-jsx": () => import("/usr/src/app/src/templates/customer-story.jsx" /* webpackChunkName: "component---src-templates-customer-story-jsx" */),
  "component---src-templates-landing-page-jsx": () => import("/usr/src/app/src/templates/landing-page.jsx" /* webpackChunkName: "component---src-templates-landing-page-jsx" */),
  "component---src-templates-integration-jsx": () => import("/usr/src/app/src/templates/integration.jsx" /* webpackChunkName: "component---src-templates-integration-jsx" */),
  "component---src-templates-partner-jsx": () => import("/usr/src/app/src/templates/partner.jsx" /* webpackChunkName: "component---src-templates-partner-jsx" */),
  "component---src-pages-404-jsx": () => import("/usr/src/app/src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-au-book-a-demo-jsx": () => import("/usr/src/app/src/pages/au/book-a-demo.jsx" /* webpackChunkName: "component---src-pages-au-book-a-demo-jsx" */),
  "component---src-pages-au-caanz-jsx": () => import("/usr/src/app/src/pages/au/caanz.jsx" /* webpackChunkName: "component---src-pages-au-caanz-jsx" */),
  "component---src-pages-au-compare-employsure-jsx": () => import("/usr/src/app/src/pages/au/compare-employsure.jsx" /* webpackChunkName: "component---src-pages-au-compare-employsure-jsx" */),
  "component---src-pages-au-platform-admin-and-reporting-jsx": () => import("/usr/src/app/src/pages/au/platform/admin-and-reporting.jsx" /* webpackChunkName: "component---src-pages-au-platform-admin-and-reporting-jsx" */),
  "component---src-pages-au-platform-hiring-and-onboarding-jsx": () => import("/usr/src/app/src/pages/au/platform/hiring-and-onboarding.jsx" /* webpackChunkName: "component---src-pages-au-platform-hiring-and-onboarding-jsx" */),
  "component---src-pages-au-platform-hr-compliance-and-advisory-jsx": () => import("/usr/src/app/src/pages/au/platform/hr-compliance-and-advisory.jsx" /* webpackChunkName: "component---src-pages-au-platform-hr-compliance-and-advisory-jsx" */),
  "component---src-pages-au-platform-leave-and-payroll-jsx": () => import("/usr/src/app/src/pages/au/platform/leave-and-payroll.jsx" /* webpackChunkName: "component---src-pages-au-platform-leave-and-payroll-jsx" */),
  "component---src-pages-au-platform-performance-review-development-jsx": () => import("/usr/src/app/src/pages/au/platform/performance-review-development.jsx" /* webpackChunkName: "component---src-pages-au-platform-performance-review-development-jsx" */),
  "component---src-pages-au-recruit-shop-jsx": () => import("/usr/src/app/src/pages/au/recruit-shop.jsx" /* webpackChunkName: "component---src-pages-au-recruit-shop-jsx" */),
  "component---src-pages-ca-index-jsx": () => import("/usr/src/app/src/pages/ca/index.jsx" /* webpackChunkName: "component---src-pages-ca-index-jsx" */),
  "component---src-pages-cms-index-jsx": () => import("/usr/src/app/src/pages/cms/index.jsx" /* webpackChunkName: "component---src-pages-cms-index-jsx" */),
  "component---src-pages-cms-white-paper-jsx": () => import("/usr/src/app/src/pages/cms/white-paper.jsx" /* webpackChunkName: "component---src-pages-cms-white-paper-jsx" */),
  "component---src-pages-nz-book-a-demo-jsx": () => import("/usr/src/app/src/pages/nz/book-a-demo.jsx" /* webpackChunkName: "component---src-pages-nz-book-a-demo-jsx" */),
  "component---src-pages-nz-caanz-jsx": () => import("/usr/src/app/src/pages/nz/caanz.jsx" /* webpackChunkName: "component---src-pages-nz-caanz-jsx" */),
  "component---src-pages-nz-compare-employsure-jsx": () => import("/usr/src/app/src/pages/nz/compare-employsure.jsx" /* webpackChunkName: "component---src-pages-nz-compare-employsure-jsx" */),
  "component---src-pages-nz-customer-stories-eat-my-lunch-jsx": () => import("/usr/src/app/src/pages/nz/customer-stories/eat-my-lunch.jsx" /* webpackChunkName: "component---src-pages-nz-customer-stories-eat-my-lunch-jsx" */),
  "component---src-pages-nz-platform-admin-and-reporting-jsx": () => import("/usr/src/app/src/pages/nz/platform/admin-and-reporting.jsx" /* webpackChunkName: "component---src-pages-nz-platform-admin-and-reporting-jsx" */),
  "component---src-pages-nz-platform-hiring-and-onboarding-jsx": () => import("/usr/src/app/src/pages/nz/platform/hiring-and-onboarding.jsx" /* webpackChunkName: "component---src-pages-nz-platform-hiring-and-onboarding-jsx" */),
  "component---src-pages-nz-platform-hr-compliance-and-advisory-jsx": () => import("/usr/src/app/src/pages/nz/platform/hr-compliance-and-advisory.jsx" /* webpackChunkName: "component---src-pages-nz-platform-hr-compliance-and-advisory-jsx" */),
  "component---src-pages-nz-platform-leave-and-payroll-jsx": () => import("/usr/src/app/src/pages/nz/platform/leave-and-payroll.jsx" /* webpackChunkName: "component---src-pages-nz-platform-leave-and-payroll-jsx" */),
  "component---src-pages-nz-platform-performance-review-development-jsx": () => import("/usr/src/app/src/pages/nz/platform/performance-review-development.jsx" /* webpackChunkName: "component---src-pages-nz-platform-performance-review-development-jsx" */),
  "component---src-pages-nz-recruit-shop-jsx": () => import("/usr/src/app/src/pages/nz/recruit-shop.jsx" /* webpackChunkName: "component---src-pages-nz-recruit-shop-jsx" */),
  "component---src-pages-nz-safetyplus-jsx": () => import("/usr/src/app/src/pages/nz/safetyplus.jsx" /* webpackChunkName: "component---src-pages-nz-safetyplus-jsx" */),
  "component---src-pages-nz-sme-employment-employer-insights-report-2023-jsx": () => import("/usr/src/app/src/pages/nz/sme-employment-employer-insights-report-2023.jsx" /* webpackChunkName: "component---src-pages-nz-sme-employment-employer-insights-report-2023-jsx" */),
  "component---src-pages-nz-unsung-hero-jsx": () => import("/usr/src/app/src/pages/nz/unsung-hero.jsx" /* webpackChunkName: "component---src-pages-nz-unsung-hero-jsx" */),
  "component---src-pages-summit-series-jsx": () => import("/usr/src/app/src/pages/summit-series.jsx" /* webpackChunkName: "component---src-pages-summit-series-jsx" */)
}

